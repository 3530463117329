import Icon from '@/components/base/icon';
import { useApp } from '@wap-client/core';
import { useRef, useState } from 'react';
import Spinner from '@/components/base/spinner';
import useSubscribe from '@/hooks/useSubscribe';

// const animations = {
//   container: {
//     hidden: {
//       opacity: 0,
//       x: -100,
//     },
//     show: {
//       opacity: 1,
//       x: 0,
//       transition: {
//         duration: 0.5,
//         delayChildren: 0.3,
//         staggerChildren: 0.15,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
//   item: {
//     hidden: {
//       opacity: 0,
//       x: -50,
//     },
//     show: {
//       opacity: 1,
//       x: 0,
//       transition: {
//         duration: 0.3,
//         ease: [0.25, 1, 0.5, 1],
//       },
//     },
//   },
// };

const Newsletter: React.FC = () => {
  const app = useApp();
  const [submitPre, setSubmitPre] = useState(false);
  const {
    formStatus,
    loadingState,
    checkPrivacy,
    setCheckPrivacy,
    onSubmit,
    formValues,
    handleChange,
  } = useSubscribe();
  const checkboxRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();

    if (!checkPrivacy) {
      setSubmitPre(true);
      setTimeout(() => {
        setSubmitPre(false);
      }, 2000);
    } else {
      setSubmitPre(false);
      onSubmit();
    }
  };

  return (
    <div
      className="newsletter"
      // variants={animations.container}
      // initial="hidden"
      // whileInView="show"
      // viewport={{ once: true }}
    >
      <h5
      // variants={animations.item}
      >
        Don’t be a stranger!
      </h5>
      <p
      // variants={animations.item}
      >
        Subscribe to our curated newsletter for a dose of Türkiye inspiration
        delivered straight to your inbox.
      </p>

      <form
        onSubmit={handleSubmit}
        //  variants={animations.item}
      >
        <div
          className="input-group"
          style={{
            outline:
              formStatus === 'duplicate'
                ? '1px solid red'
                : formStatus === 'success'
                ? '1px solid green'
                : 'none',
          }}
        >
          <input
            type="email"
            placeholder={app.settings.translations['email']}
            value={formValues.email}
            onChange={handleChange}
            required
          />

          <button type="submit">
            {loadingState ? (
              <Spinner size="xsmall" />
            ) : (
              <Icon name="icon-send" size="medium" />
            )}
          </button>
        </div>
        {formStatus === 'duplicate' && (
          <p className="form-error">
            {app.settings.translations['errorsEmail']}
          </p>
        )}
        {formStatus === 'success' && (
          <p className="form-success">
            {app.settings.translations['successEmail']}
          </p>
        )}

        <label
          className="newsletter-label-group"
          htmlFor="clarification-text"
          // variants={animations.item}
        >
          <input
            type="checkbox"
            name="clarification-text"
            id="clarification-text"
            checked={checkPrivacy}
            onChange={(e) => {
              setCheckPrivacy(e.target.checked);
            }}
          />
          <div
            className="label-group-box"
            role="checkbox"
            ref={checkboxRef}
            style={{
              border:
                submitPre && !checkPrivacy
                  ? '1px solid red'
                  : '1px solid white',
            }}
          >
            <Icon name="icon-check" />
          </div>

          <p style={{ color: submitPre && !checkPrivacy ? 'red' : 'black' }}>
            Your personal data is processed.
            <br />
            By filling out the form, you confirm that <br /> you have read and
            accepted the&nbsp;
            <a href="#" className="provicy-link">
              Clarification Text
            </a>
          </p>
        </label>
      </form>
    </div>
  );
};

export default Newsletter;
